.select-leader-dialog .el-dialog__header {
  border-bottom: #e5e5e5 1px solid;
  padding: 10px 20px 10px;
}
.select-leader-dialog .el-dialog__header .el-dialog__headerbtn {
    top: 15px;
}
.select-leader-dialog .el-dialog__body .no-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.select-leader-dialog .el-dialog__body .no-data-text {
  margin-top: 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #606266;
  line-height: 20px;
}
.select-leader-dialog .el-pagination {
  text-align: center;
  margin-top: 10px;
}
