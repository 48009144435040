.over-training-dialog .tips {
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 30px;
}
.over-training-dialog .el-form .el-input {
  width: 300px;
}
.over-training-dialog .el-form .el-input__inner {
  width: inherit;
}
