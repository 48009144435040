@charset "UTF-8";
.select-class-room-dialog .el-dialog__header {
  border-bottom: #e5e5e5 1px solid;
  padding: 10px 20px 10px;
}
.select-class-room-dialog .el-dialog__header .el-dialog__headerbtn {
    top: 15px;
}
.select-class-room-dialog .el-dialog__body .no-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.select-class-room-dialog .el-dialog__body .no-data-text {
  margin-top: 35px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #606266;
  line-height: 20px;
}
.select-class-room-dialog .classroom-style span {
  font-size: 10px;
  color: #333333;
  margin-right: 20px;
}
.select-class-room-dialog .classroom-style span i {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
}
.select-class-room-dialog .classroom-style span i.selecte {
      background: #e5e5e5;
}
.select-class-room-dialog .classroom-style span i.selected {
      background: #409eff;
}
.select-class-room-dialog .classroom-style span i.using {
      background: #f32938;
}
.select-class-room-dialog .room-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.select-class-room-dialog .room-list li {
    width: 80px;
    height: 60px;
    background: #e5e5e5;
    margin: 15px;
    text-align: center;
    color: #333333;
    font-size: 12px;
    padding: 10px;
    line-height: 23px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /* 控制显示的行数 */
    white-space: normal;
    /* 处理多余的空白字符 */
    margin-bottom: 10px;
    /* 为了更好的布局效果，可以根据需要调整 */
}
.select-class-room-dialog .room-list li.blue {
      background: #409eff;
      color: #ffffff;
}
.select-class-room-dialog .room-list li.red {
      background: #f32938;
      color: #ffffff;
}
.select-class-room-dialog .room-list li:hover {
      background: #409eff;
      color: #ffffff;
      cursor: pointer;
}
.select-class-room-dialog .el-pagination {
  text-align: center;
  margin-top: 10px;
}
